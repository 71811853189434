<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTAddButton :to="addRoute" v-if="!finalizado" />
    </template>
    <template #body>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="bg-success text-white">
            <tr class="text-center">
              <th rowspan="2">Ações</th>
              <th rowspan="2">Item</th>
              <th colspan="2" v-for="ano in anos" :key="'th-1-' + ano">
                <span class="mr-4">Ano {{ ano }}</span>
                <KTListMiniButton :to="getListRoute(ano)" />
              </th>
            </tr>
            <tr class="text-center">
              <template v-for="ano in anos">
                <th :key="'th-2-' + ano">{{ simbolo_moeda }}</th>
                <th :key="'th-3-' + ano">%</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in dre"
              :key="'tr-1-' + index"
              :class="{
                'bg-secondary': item.natureza == 1,
                'font-weight-bold': item.natureza == 1 || item.natureza == 2
              }"
            >
              <td class="text-center">
                <KTViewMiniButton
                  :to="getEditRoute(item.iddeducao)"
                  v-if="item.iddeducao && finalizado"
                />
                <KTEditMiniButton
                  class="mr-3"
                  :to="getEditRoute(item.iddeducao)"
                  v-if="item.iddeducao && !finalizado"
                />
                <KTRemoveMiniButton
                  @click="ApiRemoveItem(item.iddeducao)"
                  v-if="item.iddeducao && !finalizado"
                />
              </td>
              <td
                :class="{
                  'pl-7': item.natureza == 3,
                  'pl-9': item.natureza == 4
                }"
              >
                {{ item.descricao }}
              </td>

              <template v-for="ano in anos">
                <td :key="`td-1-${index}-${ano}`" class="text-right">
                  {{ formatCurrency(item["valor_total_ano_" + ano]) }}
                </td>
                <td :key="`td-2-${index}-${ano}`" class="text-right">
                  {{ formatNumber(item["percentual_total_ano_" + ano]) }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipDreList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-file-invoice-dollar",
        title: "Demonstrativo de Resultados do Exercício - DRE",
        description: `O Demonstrativo de Resultados do Exercício (DRE) gerencial é
          um relatório de caráter financeiro, porém, sem fins contábeis. Ele tem o
          objetivo de detalhar e confrontar as principais receitas e gastos da empresa
          em determinado período. Como resultado, o gestor consegue ter uma melhor
          percepção se o negócio apresenta lucro ou prejuízo. Através do botão Adicionar,
          insira os percentuais relativos aos impostos, comissão sobre vendas, etc.
          Utilize o botão listar localizado ao lado de cada ano para acessar o DRE mensal.`
      },
      dre: [],
      anos: null,
      simbolo_moeda: null,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/dre`;
    },
    manual() {
      return {
        session: "dre",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_dre_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.dre = res[R_GETLIST].dre;
        this.anos = res[R_GETLIST].anos;
        this.simbolo_moeda = res[R_GETLIST].simbolo_moeda;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getListRoute(id) {
      return {
        name: "pip_dre_detail_list",
        params: {
          idplano_investimento: this.idplano_investimento,
          ano: id
        }
      };
    },
    getEditRoute(id) {
      return {
        name: "pip_dre_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          iddeducao: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
